<template>
  <div>
    <p class="mb-0">
      What is the
      <stemble-latex content="$\text{pH}$" />
      of a
      <number-value :value="concHA" unit="\text{M}" />
      solution of
      <chemical-latex content="\ce{HClO4}?" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <latex-number :number="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import LatexNumber from '../displayers/LatexNumber';
import {toLatexScientificNotation} from '../../utils/math';

export default {
  name: 'Question170a',
  components: {
    StembleLatex,
    ChemicalLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    concHA() {
      return this.taskState.getValueBySymbol('concHA').content;
    },
    concHAFloat() {
      return this.concHA ? this.concHA.toFloat() : null;
    },
    concHALatex() {
      return toLatexScientificNotation(this.concHA.sympyValue);
    },
    pOH() {
      return 14 + Math.log10(this.concHAFloat);
    },
    pH() {
      return -1 * Math.log10(this.concHAFloat);
    },
    concOH() {
      return 1e-14 / this.concHAFloat;
    },
    options1() {
      return [
        {expression: '\\text{pH}=' + this.pOH.toFixed(2), value: 'pOH'},
        {expression: '\\text{pH}=\\,\\,\\,7.00', value: 'neutral'},
        {
          expression: '\\text{pH}=\\,\\,\\,' + this.pH.toFixed(2),
          value: 'pH',
        },
        {expression: '\\text{pH}=14.00', value: 'concOH'},
        {expression: '\\text{pH}=' + this.concHALatex, value: 'concHA'},
      ];
    },
  },
};
</script>
